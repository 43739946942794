import { Component, OnInit } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Observable, lastValueFrom } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { Usuario } from "src/app/modules/auth/models/usuario.model";
import { UsuarioService } from "src/app/modules/auth/services/usuario.service";
import { Router } from "@angular/router";
import { environment } from 'src/environments/environment';
import { Tipos_de_registro } from 'src/app/core/enums/parametricas.enums'
import Swal from "sweetalert2";
import { MatDialog } from "@angular/material/dialog";
import { VisorPdfDialogoComponent } from "../visor-pdf-dialogo/visor-pdf-dialogo.component";
import { TramiteService } from "../../services/tramite.service";

@Component({
  selector: "app-nav",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  public usuario: Usuario;
  public estadoFD: boolean = false;
  public showSubmenu: boolean = false;
  public isExpanded: boolean = false;
  public isShowing: boolean = false;
  showSubmenu0: boolean = false;
  public menus: any[] = [];

  nombreCompleto: string = ''

  isLoggedCD: boolean = false;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );
  public tiposDeRegistro = Tipos_de_registro
  public menuSeleccionado: any = null;
  public verFormRegistro: boolean = true

  public isProduction = environment.production
  public dgsc = environment.base_url_backend == 'https://edapi.mingobierno.gob.bo/dgsc_api' ? false : true;

  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    public _usuarioService: UsuarioService,
    public pdfModalDialog: MatDialog,
    private tramiteService: TramiteService
  ) {
    this.usuario = _usuarioService.usuario;


    let nombres = this.usuario.nombre.split(' ')
    for (const nombre of nombres) {
      this.nombreCompleto += nombre.charAt(0).toUpperCase() + nombre.slice(1).toLowerCase() + ' '
    }

    this.isLoggedCD = this.usuario.cd == "1" ? true : false;

    var pruebaProduccion = false

    let subMenuVerRegistro = {
      nombre: "Ver mi registro CENTINELA",
      img: "insert_comment",
      ruta: "/registro/ver",
    }
    let subMenuSeleccionarRegistro = {
      nombre: "Seleccionar Registro",
      img: "insert_comment",
      ruta: "/registro/seleccionar-registro",
    }
    /*    let subMenuRegistroUnico = {
         nombre: "Registros Unicos",
         img: "insert_comment",
         ruta: "/registros/registro-unico/lista-registros-unicos",
       } */
    let subMenuNuevoRegistro = {
      nombre: "Solicitud de Nuevo Registro",
      img: "insert_comment",
      ruta: "/registros/lista-registros", // vista nuevo registro
    }
    /*    let subMenuMigracionRegistro = {
         nombre: "Migraciones",
         img: "insert_comment",
         ruta: "/registros/lista-migraciones", // crear una lista para migraciones
       } */
    let subMenuRenovacionRegistro = {
      nombre: "Solicitud de Renovación",
      img: "insert_comment",
      ruta: "/registros/lista-renovacion", // crear una lista para renovacion
    }
    let subMenuInhabilitacion = {
      nombre: "Solicitud de Inhabilitación",
      img: "insert_comment",
      ruta: "/registros/lista-inhabilitacion", // crear una lista para renovacion
    }
    let subMenuRehabilitacion = {
      nombre: "Solicitud de Rehabilitación",
      img: "insert_comment",
      ruta: "/registros/rehabilitacion/lista", // crear una lista para renovacion
    }

    let subMenuLicenciaGraco = {
      nombre: "Solicitud de registro de Licencia",
      img: "insert_comment",
      ruta: "/registros/lista-licencias/lista", // crear una lista para renovacion
    }

    let subMenuCertificacionLaboratorio = {
      nombre: "Solicitud de Certificación",
      img: "insert_comment",
      ruta: "/laboratorio/registroadm/lista-adm",
    }
    let subMenuHomologacionLaboratorio = {
      nombre: "Solicitud de Homologación",
      img: "insert_comment",
      ruta: "/laboratorio/homologacion/lista-homologacion",
    }

    //Menus del sistema
    let menuDescargo = {
      nombre: "Descargos (RM)",
      img: "fact_check",
      ruta: "",
      mostrar: false,
      submenu: [
        {
          nombre: "Reportes Mensuales",
          img: "insert_comment",
          ruta: "/descargo/lista",
        },
        // {
        //   nombre: "Infracciones",
        //   img: "insert_comment",
        //   ruta: "/descargo/infracciones",
        // },
      ]
    }
    let menuReportes = {
      nombre: "Reportes",
      img: "description",
      ruta: "",
      mostrar: false,
      submenu: [
        {
          nombre: "Compras menores",
          img: "description",
          ruta: "/reportes/compras-menores",
        },
        {
          nombre: "Compras locales",
          img: "description",
          ruta: "/reportes/compras-locales",
        },
        {
          nombre: "Hojas de ruta",
          img: "description",
          ruta: "/reportes/hojas-de-ruta",
        },
      ],
    }

    let menuActualizacionDatos = {
      nombre: "Actualización de Datos",
      img: "notes",
      ruta: "",
      mostrar: false,
      submenu: [
        {
          nombre: "Act. de Datos - Sustancias",
          img: "description",
          ruta: "/registros/actualizacion-datos/tramite-lista-sustancia",
        },
        {
          nombre: "Act. de Datos - Sucursales Locales",
          img: "description",
          ruta: "/registros/actualizacion-datos/tramite-lista-sucursales",
        },
        {
          nombre: "Act. de Datos - Representantes o Apoderados",
          img: "description",
          ruta: "/registros/actualizacion-datos/tramite-lista-representantes-legales",
        },
        {
          nombre: "Act. de Datos - Maquinarias",
          img: "description",
          ruta: "/registros/actualizacion-datos/tramite-lista-maquinarias",
        },
        {
          nombre: "Actualización de Otros Datos",
          img: "description",
          ruta: "/registros/registro-nuevo/editar-datos",
          params: { tr_registro: this.tiposDeRegistro.EDICION_REGISTRO_DATOS_SIMPLES }
        }
      ],
    }
    let menuActualizacionDatosBasicos = {
      nombre: "Actualización de Datos",
      img: "notes",
      ruta: "",
      mostrar: false,
      submenu: [
        {
          nombre: "Act. de Datos - Sustancias",
          img: "description",
          ruta: "/registros/actualizacion-datos/tramite-lista-sustancia",
        },
        {
          nombre: "Act. de Datos - Sucursales Locales",
          img: "description",
          ruta: "/registros/actualizacion-datos/tramite-lista-sucursales",
        },
        {
          nombre: "Act. de Datos - Representantes o Apoderados",
          img: "description",
          ruta: "/registros/actualizacion-datos/tramite-lista-representantes-legales",
        },
      ],
    }    

    let menuRegistroBasico = {
      nombre: "Registro",
      img: "fact_check",
      ruta: "",
      mostrar: false,
      submenu: [
        subMenuVerRegistro,
        subMenuSeleccionarRegistro,
      ],
    }
    if (this.usuario.idReg == '') {
      menuRegistroBasico = {
        nombre: "Registro",
        img: "fact_check",
        ruta: "",
        mostrar: false,
        submenu: [
          subMenuSeleccionarRegistro,
        ],
      }
    }
    let menuRegistro = {
      nombre: "Registro",
      img: "app_registration",
      ruta: "",
      mostrar: true,
      submenu: [
        subMenuVerRegistro,
        subMenuSeleccionarRegistro,
        subMenuNuevoRegistro,
        // subMenuRenovacionRegistro,
        //subMenuInhabilitacion,
        //subMenuRehabilitacion,
        subMenuLicenciaGraco
      ],
    }
    if (this.usuario.idReg == '') {
      menuRegistro = {
        nombre: "Registro",
        img: "app_registration",
        ruta: "",
        mostrar: false,
        submenu: [
          subMenuSeleccionarRegistro,
          //subMenuRegistroUnico,
          subMenuNuevoRegistro,
          //subMenuMigracionRegistro,
          subMenuRenovacionRegistro,
          //subMenuInhabilitacion,
          //subMenuRehabilitacion,
          subMenuLicenciaGraco
        ],
      }
    }
    let menuLaboratorio = {
      nombre: "Laboratorio",
      img: "biotech",
      ruta: "",
      mostrar: false,
      submenu: [
        subMenuCertificacionLaboratorio,
        subMenuHomologacionLaboratorio,
      ],
    }
    let menuCompras = {
      nombre: "Compras",
      img: "shopping_cart",
      ruta: "",
      mostrar: false,
      submenu: [
        {
          nombre: "Solicitudes de ACL",
          img: "insert_comment",
          ruta: "/compras/registro/lista",
        },
        // {
        //   nombre: "Devolución de ACL",
        //   img: "insert_comment",
        //   ruta: "/compras/registro/lista-devolucion",
        // },
      ],
    }
    let menuEntregaVoluntaria = {
      nombre: "Entrega Voluntaria de Sustancias",
      img: "inbox",
      ruta: "",
      mostrar: false,
      submenu: [
        {
          nombre: "Solicitud de Entrega Voluntaria",
          img: "insert_comment",
          ruta: "/entrega/lista",
        },

      ],
    }

    let menuDestruccion = {
      nombre: "Destrucción de Sustancias",
      img: "layers_clear",
      ruta: "",
      mostrar: false,
      submenu: [
        {
          nombre: "Solicitud de Destrucción de Sustancias",
          img: "insert_comment",
          ruta: "/destruccion/lista",
        },

      ],
    }
    /*     let menuCompras = {
          nombre: "Compras",
          img: "shopping_cart",
          ruta: "",
          mostrar: false,
          submenu: [
            {
              nombre: "Solicitudes de ACL",
              img: "insert_comment",
              ruta: "/compras", 
            },
          ],
        } */
    let menuHojas = {
      nombre: "Hojas de Ruta",
      img: "local_shipping",
      ruta: "",
      mostrar: false,
      submenu: [
        {
          nombre: "Solicitudes de HR",
          img: "insert_comment",
          ruta: "/hojas",
        },
        {
          nombre: "Seguimineto de HR",
          img: "insert_comment",
          ruta: "/viajes/reporte",
        },
        /*         {
                  nombre: "Devolución de HR",
                  img: "insert_comment",
                  ruta: "/hojas/devoluciones",
                }, */
        /*{
          nombre: "Anulación de HR",
          img: "insert_comment",
          ruta: "/hojas/lista-anulacion",
        },*/

      ],
    }
    let menuLicencias = {
      nombre: "Autorizaciones Previas",//swap_horiz  cached
      img: "flight",
      ruta: "",
      mostrar: false,
      submenu: [
        // {
        //   nombre: "Tipo de Documento",
        //   img: "description",
        //   ruta: "/licencias-previas/tipo-documento-notificacion/lista",
        // },
        {
          nombre: "Solicitudes de AP",
          img: "description",
          ruta: "/licencias-previas/bandeja-licencias-previas/lista",
        },
        {
          nombre: "Solicitud de Notificaciones de AP",
          img: "description",
          ruta: "/licencias-previas/notificaciones-licencias-previas/lista",
        },
      ],
    }

    // let menuInfracciones = {
    //   nombre: "Infracciones y Sanciones",//swap_horiz  cached
    //   img: "insert_comment",
    //   ruta: "",
    //   mostrar: false,
    //   submenu: [
    //     // {
    //     //   nombre: "Tipo de Documento",
    //     //   img: "description",
    //     //   ruta: "/licencias-previas/tipo-documento-notificacion/lista",
    //     // },
    //     {
    //       nombre: "Infracciones",
    //       img: "description",
    //       ruta: "/infraccion/infracciones/lista",
    //     },
    //   ],
    // }


    let menuTramites = {
      nombre: "Dev./Anula Trámites",//swap_horiz  cached
      img: "bookmark",
      ruta: "",
      mostrar: false,
      submenu: [
        {
          nombre: "Solicitud de Devolución de Trámite",
          img: "description",
          ruta: "/tramite/proceso-tramites/lista",
        },
        {
          nombre: "Solicitud de Anulación de Trámite",
          img: "description",
          ruta: "/tramite/proceso-tramites/lista-anulaciones",
        },
      ],
    }

    //tenemos id registro SIRCOF->mostramos las opciones de licencias, registros nuevas opciones, laboratorio, etc
    if (this.usuario.num.length == 0) {
      // console.log("Modo 3: Invitado en sistema ",this.usuario);

      //Modo 3: Invitado en sistema 
      //Estamos como invitados->solo opciones minimas para invitados , tb comentamos de momento el laboratorio
      this.menus = [
        {
          nombre: "Registro",
          img: "app_registration",
          ruta: "",
          mostrar: false,
          submenu: [
            subMenuSeleccionarRegistro,
            // subMenuRegistroUnico,
            subMenuNuevoRegistro
          ],
        },
        {
          nombre: "Laboratorio",
          img: "biotech",
          ruta: "",
          mostrar: false,
          submenu: [
            subMenuCertificacionLaboratorio,
          ],
        },
      ];
      console.log('a')
      if (environment.production || pruebaProduccion) {
        this.menus = [
          {
            nombre: "Registro",
            img: "app_registration",
            ruta: "",
            mostrar: false,
            submenu: [
              subMenuSeleccionarRegistro,
              subMenuNuevoRegistro,
            ],
          },
          {
            nombre: "Laboratorio",
            img: "description",
            ruta: "",
            mostrar: false,
            submenu: [
              subMenuCertificacionLaboratorio,
            ],
          },
        ];
      }
    } else {
      //tenemos un registro, verificamos si ya esta en el nuevo sistema
      if (this.usuario.idR > 0) {

        //Modo 1: Administrado en nuevo sistema 
        //tenemos un registro en el nuevo sistema->podemos mostrar todas las acciones de compras, licencias, actualizaciones de registro
        this.menus = [
          menuRegistro,
          menuLaboratorio,
          menuHojas,
          //menuEntregaVoluntaria,
          menuTramites,
          //menuDestruccion,
          menuDescargo,
          menuCompras,
          menuActualizacionDatosBasicos,
          menuLicencias,
          // menuReportes,
          // menuInfracciones
        ];
      } else {

        //Modo 2: Migracion en sistema
        //no tenemos aun registro en nuevo sistema->solitamos migracion
        this.menus = [
          {
            nombre: "Registro",
            img: "app_registration",
            ruta: "",
            mostrar: false,
            submenu: [
              subMenuVerRegistro,
              subMenuSeleccionarRegistro,
              //  subMenuMigracionRegistro,
              //  subMenuRegistroUnico
            ],
          },
        ];
        if (environment.production || pruebaProduccion) {
          //solo mostramos descargos de momento
          this.menus = [
            {
              nombre: "Registro",
              img: "app_registration",
              ruta: "",
              mostrar: false,
              submenu: [
                subMenuVerRegistro,
                subMenuSeleccionarRegistro,
                // subMenuMigracionRegistro
              ],
            },
            menuDescargo
            // , menuReportes
            , {
              nombre: "Laboratorio",
              img: "description",
              ruta: "",
              mostrar: false,
              submenu: [
                subMenuCertificacionLaboratorio,
              ],
            },
          ];
        }
      }
    }
  }
  ngOnInit(): void {
    this.verFormRegistro = this.usuario.idR ? true : false
  }

  ngAfterViewInit(): void { }

  cambiarClaveUsuario() {
    let ruta = '/cambiar-clave/' + btoa(this.router.url)
    this.router.navigate([ruta]);
  }

  clickMenu(menu: any) {
    // Cambiar el estado de la propiedad mostrar del menú
    menu.mostrar = !menu.mostrar;
  }

  async buscarRegistro() {
    Swal.fire({ title: '', text: 'Obteniendo los datos del Registro, espere por favor', icon: 'info', allowOutsideClick: false })
    Swal.showLoading()

    try {
      var formularioPDF = await lastValueFrom(this.tramiteService.verFormularioRegistro())
      Swal.close()

      // Visualizamos el formulario de Registro en formato pdf
      const dialogModalPdf = this.pdfModalDialog.open(VisorPdfDialogoComponent, {
        disableClose: true,
        data: { titulo: 'Formulario de Registro', archivoBase64: formularioPDF.datos.pdf64, disableCancelButton: true }
      })

      // Obtenemos la respuesta del boton que presiono en el modal
      await lastValueFrom(dialogModalPdf.afterClosed())
    } catch (error) {
      Swal.fire({ text: error.error.mensaje, icon: 'error', iconHtml: '!', confirmButtonText: 'Aceptar' })
      return
    }
  }

  async buscarCertificadoRegistro() {
    Swal.fire({ title: '', text: 'Obteniendo los datos del Registro, espere por favor', icon: 'info', allowOutsideClick: false })
    Swal.showLoading()
    console.log("USUARIO", this.usuario.idR);
    try {
      var formularioPDF = await lastValueFrom(this.tramiteService.verCertificadoRegistro())
      Swal.close()
      
      // Visualizamos el formulario de Registro en formato pdf
      const dialogModalPdf = this.pdfModalDialog.open(VisorPdfDialogoComponent, {
        disableClose: true,
        data: { titulo: 'Formulario de Registro', archivoBase64: formularioPDF.datos.pdf64, disableCancelButton: true }
      })

      // Obtenemos la respuesta del boton que presiono en el modal
      await lastValueFrom(dialogModalPdf.afterClosed())
    } catch (error) {
      Swal.fire({ text: error.error.mensaje, icon: 'error', iconHtml: '!', confirmButtonText: 'Aceptar' })
      return
    }
  }
}
