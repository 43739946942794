import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { SwalObject } from '../../utils/swal';
import { Subscription, lastValueFrom } from 'rxjs';
import { MatTable } from '@angular/material/table';
import { Location } from '@angular/common'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RegistroUnicoModel } from 'src/app/modules/registros/models/registroUnico.model'; 
import { RequisitosModel } from 'src/app/modules/registros/models/requisitos.model';
import Swal from 'sweetalert2';
import { SucursalLocalModel } from 'src/app/modules/registros/models/sucursalLocal.model';
import { TramiteRegistroService } from 'src/app/modules/registros/services/tramiteRegistro.service';
import { UsuarioService } from 'src/app/modules/auth/services/usuario.service';
import { Tipos_de_registro } from 'src/app/core/enums/parametricas.enums'
import { MatTabGroup } from '@angular/material/tabs';
import { DocumentosAdicionalesService } from '../../services/documentos-adicionales.service'; 
import { StepperModel } from 'src/app/modules/registros/models/stepper.model'; 

@Component({
  selector: 'app-importacion-comercializacion',
  templateUrl: './importacion-comercializacion.component.html',
  styleUrls: ['./importacion-comercializacion.component.scss'],
})
export class ImportacionComercializacionComponent implements OnInit, AfterViewInit {

  subscription: Subscription;
  idTramite: any;
  tramite_Id: any;
  selectedValue: number;
  tipoPersoneria: any
  costoCategoria: any = {}
  datosRubro: any = '';
  datosOficina: any = '';
  formCosto: FormGroup;
  sucursalPrincipal: Array<SucursalLocalModel> = [];
  datosRegistro: any = {}
  formDatosGenerales: FormGroup;
  formUnico: FormGroup;
  formPrincipal: FormGroup;
  formRepresentanteLegal: FormGroup;
  formRepresentanteTecnico: FormGroup;
  formSustancia: FormGroup;
  formSucursal: FormGroup;
  formMaquinaria: FormGroup;
  swal = new SwalObject();
  mostrarBotonSiguiente: any
  guardarRegistro = false
  ocultarSiguiente: boolean = true
  mostrarCancelarRegistro = true
  ocultarCancelar: boolean = true
  pasoDeshabilitado = true;
  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChild('stepper') stepper!: MatStepper;

  @ViewChild('tabGroup', { static: false }) tabGroup: MatTabGroup;
  @Output() formSucursalOuput = new EventEmitter<any>();
  stepperModel = new StepperModel();
  nro_tramite: any
  subjects = { nombre: 'repres' }
  public datosGenerales: RegistroUnicoModel = {};
  public datosTramite: any = {}
  public requisitos: RequisitosModel[] = []
  public archivos: any = []
  public displayedColumns: string[] = ['acciones', 'nombre_sucursal', 'nombre_municipio', 'zona', 'propio'];
  dataSource: Array<any> = [];
  formDocumento!: FormGroup;
  quintoFormGroup: any;
  textoBotonGuardar: string = 'Finalizar registro'
  tipoDeRegistro: number = 0
  subtipoRegistro: number = 0
  tiposDeRegistro: any = Tipos_de_registro
  tituloForm: string = ''
  labelForm: string = ''
  legal: boolean = false;
  mostrarNro: boolean = true;
  tecnico: boolean = false;
  pasoSustanciasHabilitado: boolean = false
  public TipoDistincionEntidad: any
  public datosRegistroUnico: any
  public formBusqueda: FormGroup

  representantesDB: any[] = []
  municipiosDB: any[] = []

  constructor(
    public usuarioService: UsuarioService,
    public pdfModalDialog: MatDialog,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private tramiteRegistroService: TramiteRegistroService,
    private _location: Location,
    private router: Router,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private documentosAdicionalesService: DocumentosAdicionalesService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ImportacionComercializacionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.dialogRef.updateSize('70%', '65%')
    this.dialogRef.disableClose = true
    const tipoRegistroParam = this.activatedRoute.snapshot.queryParamMap.get('tr_registro')
    const subtipoRegistro = this.activatedRoute.snapshot.queryParamMap.get('tr_subtipo_registro')
    
  }
  // Escucha el evento emitido por app-principal-sucursal

  ngAfterViewInit(): void {
  }

  /**obtenemos datos generales para guardar */

  goBack() {
    this._location.back()
    this.router.navigate(['/registros/lista-registros'])
  }

  async retornar() {
    console.log("RETORNAR 2222");
    var respuesta = await Swal.fire({
      title: "¿Está seguro de que desea volver al listado de registros?", text: "¡No podrás revertir esto! ", icon: "question",
      showCancelButton: true, confirmButtonText: "Si.",
      cancelButtonText: "No.",
      showCloseButton: true
    })
    if (!respuesta.isConfirmed) return
    Swal.fire("", "Espere por favor", "info");
    Swal.fire({
      title: "Espere por favor...",
      allowOutsideClick: false,
    });
    Swal.showLoading()
    this.router.navigateByUrl('/registros/lista-registros');
    Swal.close()
  }


  async botonSiguiente(value: any) {
    console.log("values sucursal", value);

    if (value?.tipo == 1){
      this.stepper.next();
    }
    if (value?.tipo == 2){    
      this.router.navigateByUrl('/registros/lista-registros');
    }



    // if (value?.formSucursal?.id_tipo_sucursal == Tipo_dato.SUCURSAL_PRINCIPAL) {
    //   if (value?.formSucursal?.id_sucursal) {
    //     this.formPrincipal.controls['direccionPrincipal'].setValue(value?.formSucursal?.id_sucursal)
    //     this.stepper.selectedIndex = 2;
    //   }
    // }

    // if (value?.tipo == Tipo_dato.SUSTANCIA_REGISTRO) {

    //   // Obtenemos los propietarios, o apoderados del registro unico
    //   let listaRepresentantes
    //   const idTramite = this.idTramite || this.datosTramite?.id_tramite
    //   try {
    //     // Si el tipo de Registro es Nuevo
    //     if (this.tipoDeRegistro === 1 || this.tipoDeRegistro === 2 
    //       || this.tipoDeRegistro === 3 || this.tipoDeRegistro === 4 || this.tipoDeRegistro === 5) {
    //       // listaRepresentantes = await lastValueFrom(this.registroService.listaRepresentantesLegalesTramite({ idTramite: idTramite }))
    //     } else {
    //       // listaRepresentantes = await lastValueFrom(this.registroService.listaRepresentantesLegales())
    //     }
    //   } catch (error) {
    //     Swal.fire({ text: error.error.mensaje, icon: 'warning', confirmButtonText: 'Aceptar' })
    //     return
    //   }
    //   listaRepresentantes = listaRepresentantes.datos.representantesLegal
    //   this.representantesDB = listaRepresentantes
    //   if (value?.listaSustancia?.length) {
    //     this.formSustancia.controls['listaSustancia'].setValue(value.listaSustancia)
    //     this.guardarRegistro = true
    //     this.stepper.selectedIndex = 3;
    //   }
    // }

    // if (value?.tipo == Tipo_dato.APODERADO) {
    //   this.formRepresentanteLegal.controls['represLegal'].setValue(value.formRepresentante)
    //   this.stepper.selectedIndex = 4;
    // }

    // /**podria o no tener maquinaria */
    // if (value?.tipo == Tipo_dato.MAQUINARIA) {
    //   this.formMaquinaria.controls['listaMaquinaria'].setValue(value.listaMaquinaria)
    //   let idRegistro = this.datosTramite?.id_registro || this.datosRegistro.data.resRegistro.id_registro
    //   try {
    //     // const respMunicipios = await lastValueFrom(this.municipioService.obtenerMunipiosAreaMetropolitana(idRegistro))
    //     // this.municipiosDB = respMunicipios.datos.municipios
    //   } catch (error) {
    //     Swal.fire({ text: error.error.mensaje, icon: 'warning', confirmButtonText: 'Aceptar' })
    //   }
    //   this.stepper.selectedIndex = 5;
    // }

    // if (value?.tipo == Tipo_dato.SUCURSAL_LOCALES) {
    // }
  }

  listaRegistr() {
    this.router.navigateByUrl('/registros/lista-registros');
  }

}
