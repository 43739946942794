<form [formGroup]="importacionForm"> 
  <mat-card-title style="text-align: center; font-size: 20px;">SOLICITUD DE IMPORTACIÓN</mat-card-title>
 
  <mat-card-content>
    <mat-card-title class="title" style="text-align: left;"> I. DATOS GENERALES</mat-card-title>
    <div class="row" style="font-size: 13px;">
      <div class="col-md-3">
        <mat-form-field appearance="outline" style="width: 90%;" class="disabled">
          <mat-label>Nombre/Razón Social</mat-label>
          <input matInput type="text" value="{{this._usuarioService.usuario.rz || this.razonSocial}}" disabled>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="outline" style="width: 90%;" class="disabled">
          <mat-label>Representante Legal</mat-label>
          <input matInput type="text" value="{{this._usuarioService.usuario.nombre}}" disabled>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="outline" style="width: 90%;" class="disabled">
          <mat-label>Número de Registro</mat-label>
          <input matInput type="text" value="{{this._usuarioService.usuario.num || this.registro}}" disabled>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="outline" style="width: 90%;" class="disabled">
          <mat-label>Correo</mat-label>
          <input matInput type="text" value="{{this._usuarioService.usuario.correo}}" disabled>
        </mat-form-field>
      </div>
    </div>
  </mat-card-content>



  <mat-card-content>
    <!--  <h4><strong>II. DATOS DE LA SOLICITUD</strong></h4> -->
    <mat-card-title class="title" style="text-align: left;"> II. DATOS DE LA SOLICITUD</mat-card-title>
    <div class="row" style="font-size: 13px;">
      <div class="col-md-4">
        <mat-form-field style="width:90%" appearance="outline">
          <mat-label>Nombre del proveedor</mat-label>
          <input matInput type="text" style="width:100%" class="text-uppercase" formControlName="comprador"  required>
          <mat-error *ngIf='importacionForm.controls["comprador"].hasError("required")'>Campo requerido</mat-error>
          <mat-error *ngIf="importacionForm.get('comprador').hasError('pattern')">No se permiten espacios al inicio o al final</mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field style="width:90%" appearance="outline">
          <mat-label>Lugar de desaduanización</mat-label>
          <mat-select formControlName="id_aduana" required>
            <mat-option *ngFor="let aduana of aduanas"  class="text-uppercase" [value]="aduana.id_aduana">
              {{ aduana.nombre_aduana }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf='importacionForm.controls["id_aduana"].hasError("required")'>Campo requerido</mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field style="width:90%" appearance="outline">
          <mat-label>Tiempo de vigencia </mat-label>
          <mat-select formControlName="dias_vigencia" required>
            <mat-option *ngFor="let dia of dias"  [value]="dia.value">
              {{dia.viewValue}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf='importacionForm.controls["dias_vigencia"].hasError("required")'>Campo
            requerido</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row" style="font-size: 13px;">
      <div class="col-md-4">
        <mat-form-field style="width:90%" appearance="outline">
          <mat-label>País de origen</mat-label>
          <mat-select formControlName="id_pais_origen" required (selectionChange)="onSelectionChangedOrigen($event)">
            <mat-option [value]=0>Ninguno</mat-option>
            <mat-option *ngFor="let pais of paises"  [value]="pais.id_pais">
              {{ pais.nombre_pais }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf='importacionForm.controls["id_pais_origen"].hasError("required")'>Campo
            requerido</mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field style="width:90%" appearance="outline">
          <mat-label>País de última procedencia</mat-label>

          <mat-select formControlName="id_pais_ultima_procedencia" required
            (selectionChange)="onSelectionChangedProcedencia($event)">
            <mat-option [value]=0>Ninguno</mat-option>
            <mat-option *ngFor="let pais of paises" [value]="pais.id_pais">
              {{ pais.nombre_pais }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf='importacionForm.controls["id_pais_ultima_procedencia"].hasError("required")'>Campo
            requerido
          </mat-error>
        </mat-form-field>
      </div>

    </div>
    <div class="row" style="font-size: 13px;">
      <div class="col-md-4">
        <mat-form-field style="width:90%" appearance="outline">
          <mat-label>Lugar origen</mat-label>
          <input matInput type="text"  class="text-uppercase" formControlName="origen" style="width:100%"
            matTooltip="En caso de no pertenecer a ningún país origen (Ej: ULTRAMAR, HONG KONG)">    
            <mat-error *ngIf="importacionForm.get('origen').hasError('pattern')">No se permiten espacios al inicio o al final</mat-error>       
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field style="width:90%" appearance="outline">
          <mat-label>Lugar procedencia</mat-label>
          <input matInput type="text"  class="text-uppercase"  formControlName="procedencia" style="width:100%"
            matTooltip="En caso de no pertenecer a ningún país de procedencia (Ej: ULTRAMAR, HONG KONG)">   
            <mat-error *ngIf="importacionForm.get('procedencia').hasError('pattern')">No se permiten espacios al inicio o al final</mat-error>        
        </mat-form-field>
      </div>
    </div>
  </mat-card-content>


  <mat-card-content>
    <mat-card-title class="title" style="text-align: left;">III. DATOS DE LA(S) SUSTANCIA(S)</mat-card-title>   

    <div class="row">
      <mat-card-title class="alert d-flex align-items-center fade show"><mat-icon>info</mat-icon> 
        <div [innerHTML]="MesSustancia"></div>
      </mat-card-title>
      <div class="col-md-12">
        <button mat-raised-button matTooltip="Agregar sustancias registradas" class="nuevo-button" *ngIf="!activar"
          (click)="agregarSustancias()" [disabled]="verBoton()">
          <mat-icon>add_box</mat-icon> Agregar sustancias
        </button> &nbsp;
        <button mat-raised-button matTooltip="Agregar en caso de no exitir envase" (click)="agregarEnvases()">
          <mat-icon class="color-verde-2">add</mat-icon>Gestión envases
        </button>
      </div>
    </div>
    <br>
    <!-- <div class="mat-elevation-z8"> -->
      <form [formGroup]="sustanciasForm">
        <table class="table table-list">
          <thead *ngIf="sustanciasArray.controls.length > 0">
            <tr>
              <th>N°</th>
              <th>Acciones</th>
              <th>Sustancias (sinónimo o producto terminado)</th>
              <th>Tip. sustancia</th>
              <th>Unidad medida</th>
              <th>Cantidad solicitada</th>
              <th>Saldos a la fecha</th>
              <th>Destino o utilización</th>
              <th></th>
              <th></th>
              <th>Detalle de Envase(s)</th>             
              <th>Cant. según Envase(s)</th>
            </tr>
          </thead>
          <tbody formArrayName="sustanciasArray"
            *ngFor="let sustanciaForm of sustanciasArray.controls; let i = index;">
            <tr [formGroupName]="i">
              <td>
                {{ i + 1 }}
              </td>
              <td>           
                <button mat-icon-button color="warn" matTooltip="Eliminar Sustancia"
                  (click)="eliminaSustancia(i,id_sustancia=sustanciaForm.value.id_sustancia)" *ngIf="!activar">
                  <img class="color-delete" src="assets/svg/delete_forever.svg" />
                </button>
              </td>
              <td> {{ sustanciaForm.value.sustancia_nombre }} ({{ sustanciaForm.value.sustancia_primaria }})</td>

              <td>
                <span>Primaria {{sustanciaForm.value.tipo_sustancia==true? '(S)' : '(N)' }}</span>
                <br>
                <span>{{ sustanciaForm.value.porcentaje }} %</span>
              </td>
              <td>{{ sustanciaForm.value.unidad_medida }}</td>
              <td>
                <mat-form-field appearance="outline" style="width: 120px;">
                  <mat-label>Cantidad Solicitada</mat-label>
                  <input matInput type="number" style="width: 120px;" min="0.00001"
                    onKeyPress="if(this.value.length==30) return false;"  formControlName="cantidad_solicitada"
                    (keypress)="validarFormatoNumero($event)" required number>
                  <mat-error *ngIf='sustanciaForm.controls["cantidad_solicitada"].hasError("required")'>Campo
                    requerido</mat-error>
                   
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="outline" style="width: 120px;">
                  <mat-label>Saldos a la fecha</mat-label>
                  <input matInput type="number" style="width: 120px;" min="0"
                    onKeyPress="if(this.value.length==30) return false;" formControlName="saldo_kardex"
                    (keypress)="validarFormatoNumero($event)" required>
                  <mat-error *ngIf='sustanciaForm.controls["saldo_kardex"].hasError("required")'>Campo
                    requerido</mat-error>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="outline">
                  <mat-label>Destino o utilización</mat-label>
                  <textarea matInput type="text" placeholder=""  class="text-uppercase" formControlName="utilizacion" required
                 maxlength="250" >
                  </textarea>
                  <mat-error *ngIf='sustanciaForm.controls["utilizacion"].hasError("required")'>Campo requerido</mat-error>
                  <mat-error *ngIf="sustanciaForm.get('utilizacion').hasError('pattern')">No se permiten espacios al inicio o al final</mat-error> 
                </mat-form-field>
              </td>
              <td>
             <!--    <button mat-icon-button  matTooltip="{{ sustanciaForm.value.base64 == null ? 'Adjuntar archivo Formulario de control' : 'Cambiar archivo' }}"
                [ngClass]="sustanciaForm.value.base64 == null ? 'file-not-uploaded' : 'file-uploaded'"
                (click)="agregarDocumento(sustanciaForm, i)"
                matTooltipClass="tooltip-background">
                <img class="color-upload" src="assets/svg/attach_file_white.svg"/> -->
                <!-- <div *ngIf="formsAreValid() && importacionForm.touched && sustanciasArray.touched" style="color: rgb(228, 4, 4); font-size: 10px;">
                  Debes seleccionar el botón antes de continuar.
                </div> -->
            <!--   </button>  -->
            <!--   <label matTooltip="Adjuntar Formulario de Control">
                <img class="color-edit" src="assets/svg/attach_file_white.svg"/>                 
                <input type="file" (change)="agregarDocumento($event,sustanciaForm)" style="display: none;" accept=".pdf">
              </label>  -->
                             
              </td>
              <td><button mat-icon-button matTooltip="Agregar envases" (click)="agregarEnvasesLote(sustanciaForm)"
                matTooltipClass="tooltip-background">
                <img class="color-fill" src="assets/svg/format_color_fill_white.svg"/>
              </button></td>
              <td>{{ sustanciaForm.value.tipo_envase }}</td>
              
              <td>{{ sustanciaForm.value.cantidad_envases.toLocaleString("es-BO") }} {{ sustanciaForm.value.abreviacion }}</td>
            </tr>
          </tbody>         
        </table>
      </form>
<!--     </div> -->

<!--     <div class="row">
      <mat-card-subtitle style="font-size: 16px; color: rgb(83, 81, 81);text-align: right;"><strong> Monto:
          {{this.monto*80}} Bs</strong></mat-card-subtitle>
    </div> -->
  </mat-card-content>
  <mat-card-content>          
    <mat-card-title class="title" style="text-align: left;"> IV. REQUISITOS OBLIGATORIOS</mat-card-title> 
    <div class="row">
      <mat-card-title class="alert d-flex align-items-center fade show"><mat-icon>info</mat-icon> 
        <div [innerHTML]="MesDocumento"></div>
      </mat-card-title>
    </div>      
   <!--  <div class="mat-elevation-z8"> -->
    <form [formGroup]="formDocumentos">
    <table class="table table-list">
      <thead *ngIf="documentos.controls.length > 0">
        <tr>
          <th>N°</th>
          <th>Acciones</th>          
          <th>Requisito Obligatorio</th>          
          <th>Número y/o descripción de Documento</th>          
          <th>Fecha de Emisión</th>          
          <th>Fecha Finalización</th>
          <th></th>              
      
        </tr>
      </thead>
      <tbody formArrayName="documentosArray"
      *ngFor="let docObligatorioForm of documentos.controls; let i=index">
      <tr [formGroupName]="i">  
        <td>          
          {{ i + 1 }}         
        </td> 
        <td style="width: 10%;">   
          <button mat-icon-button (click)="verDocumentoAdj(docObligatorioForm)" matTooltip="Ver documento" *ngIf="verArchivo(docObligatorioForm)">
            <img class="color-print" src="assets/svg/visibility_white.svg"/>    
          </button>
          <button mat-icon-button (click)="downloadExcelFormularioControl()" matTooltip="Descargar Plantilla (Formulario de Control)" *ngIf="verArchivoExcel(docObligatorioForm)">
            <!-- <img src="assets/svg/filetype-xlsx.svg" class="color-edit" height="30" width="26">   -->
            <img src="assets/images/excel.png"  height="26" width="26">  
          </button>
         <!--  <label  matTooltip="{{ docObligatorioForm.value.base64 == null ? 'Adjuntar archivo' : '¿Actualizar archivo?' }}" *ngIf="verSinRA(docObligatorioForm)"  [ngClass]="{'color-edit': docObligatorioForm.value.base64 == null , 'color-upload': docObligatorioForm.value.base64 == null }"> -->
          <label  matTooltip="{{ docObligatorioForm.value.base64 == null ? 'Adjuntar archivo' : '¿Actualizar archivo?' }}"   [ngClass]="{'color-edit': docObligatorioForm.value.base64 == null , 'color-upload': docObligatorioForm.value.base64 == null }">
            <!--  <img class="color-upload" [src]="archivosAdjuntados[i] ? 'assets/svg/verified_white_24dp.svg' : 'assets/svg/attach_file_white.svg'"/> -->
            <img class="color-upload" [src]="archivosAdjuntados[i] ? 'assets/svg/verified_white_24dp.svg' : 'assets/svg/attach_file_white.svg'" [ngClass]="{'color-edit': archivosAdjuntados[i], 'color-upload': !archivosAdjuntados[i]}">
             <input type="file" (change)="adjuntarArchivo($event,docObligatorioForm,i)" style="display: none;" accept=".pdf">
           </label>
           
          <!--  <button mat-stroked-button color="success" matTooltip="Descargar Plantilla de Formulario de Control" class="mx-3"
           aria-label="Descargar Plantilla de Formulario de Control" (click)="downloadExcelFormularioControl()"
           *ngIf="verArchivoExcel(docObligatorioForm)">
           <img src="assets/images/excel.png" class="icon-png-button" alt="Excel file">
           Descargar Plantilla
         </button> -->
      

             <button mat-icon-button (click)="verDocumento(docObligatorioForm)" matTooltip="Ver documento" *ngIf="verUltimaRA(docObligatorioForm)">
               <img class="color-print" src="assets/svg/visibility_white.svg"/>    
             </button>  
            
        </td>
        <td style=" text-align:left"> {{ docObligatorioForm.value.nombre_documento }}</td>     
          <td>
            <mat-form-field appearance="outline" >
              <mat-label>Número de Documento</mat-label>
              <input matInput type="text" class="text-uppercase" onKeyPress="if(this.value.length==50)  return false;"  required formControlName="identificador" >
              <mat-error *ngIf='docObligatorioForm.controls["identificador"].hasError("required")'>Campo
                requerido</mat-error> 
                <mat-error *ngIf="docObligatorioForm.get('identificador').hasError('pattern')">No se permiten espacios al inicio o al final</mat-error> 
            </mat-form-field>
          </td>
          <td>
            <!-- <mat-form-field appearance="outline" >
              <mat-label>Fecha de Emisión</mat-label>
              <input matInput type="date"  onkeydown="return false" formControlName="fecha_emision"                         
                required>
              <mat-error *ngIf='docObligatorioForm.controls["fecha_emision"].hasError("required")'>Campo
                requerido</mat-error> 
            </mat-form-field> -->
            <mat-form-field appearance="outline">
              <mat-label>Fecha de Emisión</mat-label>
              <input matInput [matDatepicker]="picker" formControlName="fecha_emision" readonly>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>   
              <mat-error *ngIf='docObligatorioForm.controls["fecha_emision"].hasError("required")'>Campo
                requerido</mat-error>                 
            </mat-form-field>
          </td>
          <td>
          <!--   <mat-form-field appearance="outline" >
              <mat-label>Fecha Finalización</mat-label>
              <input matInput type="date"  onkeydown="return false"  formControlName="fecha_fin" >
            </mat-form-field> -->
            <mat-form-field appearance="outline">
              <mat-label>Fecha Finalización</mat-label>
              <input matInput [matDatepicker]="pickerF" formControlName="fecha_fin" readonly>
              <mat-datepicker-toggle matSuffix [for]="pickerF"></mat-datepicker-toggle>
              <mat-datepicker #pickerF></mat-datepicker>   
              <mat-error *ngIf='docObligatorioForm.controls["fecha_fin"].hasError("required")'>Campo
                requerido</mat-error>                 
            </mat-form-field>
          </td>
       
        </tr>
      </tbody>
    </table>
  </form>
 <!--    </div> -->
 <!--  -->

  </mat-card-content>
  <mat-card-content>          
    <mat-card-title class="title" style="text-align: left;"> V. REQUISITOS IMPORTACION (CUANDO CORRESPONDA)</mat-card-title>     
    <form [formGroup]="formDocumentosCorresponde">
      <table class="table table-list">
        <thead *ngIf="documentosCorresponde.controls.length > 0">
          <tr>
            <th>N°</th>
            <th>Acciones</th>          
            <th>Requisito</th>          
            <th>Número y/o descripción de Documento</th>          
            <th>Fecha de Emisión</th>          
            <th>Fecha Finalización</th>
            <th></th>              
        
          </tr>
        </thead>
        <tbody formArrayName="documentosCorrespondeArray"
        *ngFor="let docCorrespondeForm of documentosCorresponde.controls; let j=index">
        <tr [formGroupName]="j">  
          <td>          
            {{ j + 1 }}         
          </td> 
          <td>   
            <button mat-icon-button color="warn" matTooltip="Eliminar Documento"
            (click)="eliminarDocumento(j)" >
            <img class="color-delete" src="assets/svg/delete_forever.svg" />
          </button>

            <button mat-icon-button (click)="verDocumentoCorrespondeAdj(docCorrespondeForm)" matTooltip="Ver documento" *ngIf="verArchivoCorresponde(docCorrespondeForm)">
              <img class="color-print" src="assets/svg/visibility_white.svg"/>    
            </button>
            <button mat-icon-button (click)="downloadExcelFormularioControl()" matTooltip="Descargar Plantilla (Formulario de Control)" *ngIf="verArchivoExcel(docCorrespondeForm)">
              <!-- <img src="assets/svg/filetype-xlsx.svg" class="color-edit" height="30" width="26">   -->
              <img src="assets/images/excel.png"  height="26" width="26">  
            </button>
            <label  matTooltip="{{ docCorrespondeForm.value.base64 == null ? 'Adjuntar archivo' : '¿Actualizar archivo?' }}"  [ngClass]="{'color-edit': docCorrespondeForm.value.base64 == null , 'color-upload': docCorrespondeForm.value.base64 == null }">
              <img class="color-upload" [src]="archivosAdjuntadosCorresponde[j] ? 'assets/svg/verified_white_24dp.svg' : 'assets/svg/attach_file_white.svg'" [ngClass]="{'color-edit': archivosAdjuntadosCorresponde[j], 'color-upload': !archivosAdjuntadosCorresponde[j]}">
               <input type="file" (change)="adjuntarArchivoCorresponde($event,docCorrespondeForm,j)" style="display: none;" accept=".pdf">
             </label>

               <button mat-icon-button (click)="verDocumento(docCorrespondeForm)" matTooltip="Ver documento" *ngIf="verUltimaRA(docCorrespondeForm)">
                 <img class="color-print" src="assets/svg/visibility_white.svg"/>    
               </button>
            
              
          </td>
          <td style=" text-align:justify"> {{ docCorrespondeForm.value.nombre_documento }}</td>     
            <td>
              <mat-form-field appearance="outline" >
                <mat-label>Número de Documento</mat-label>
                <input matInput type="text" class="text-uppercase" required formControlName="identificador">
                <mat-error *ngIf='docCorrespondeForm.controls["identificador"].hasError("required")'>Campo
                  requerido</mat-error> 
                  <mat-error *ngIf="docCorrespondeForm.get('identificador').hasError('pattern')">No se permiten espacios al inicio o al final</mat-error> 
              </mat-form-field>
            </td>
            <td>
              <!-- <mat-form-field appearance="outline" >
                <mat-label>Fecha de Emisión</mat-label>
                <input matInput type="date"  onkeydown="return false" formControlName="fecha_emision"                         
                  required>
                <mat-error *ngIf='docObligatorioForm.controls["fecha_emision"].hasError("required")'>Campo
                  requerido</mat-error> 
              </mat-form-field> -->
              <mat-form-field appearance="outline">
                <mat-label>Fecha de Emisión</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="fecha_emision" readonly>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>   
                <mat-error *ngIf='docCorrespondeForm.controls["fecha_emision"].hasError("required")'>Campo
                  requerido</mat-error>                 
              </mat-form-field>
            </td>
            <td>
            <!--   <mat-form-field appearance="outline" >
                <mat-label>Fecha Finalización</mat-label>
                <input matInput type="date"  onkeydown="return false"  formControlName="fecha_fin" >
              </mat-form-field> -->
              <mat-form-field appearance="outline">
                <mat-label>Fecha Finalización</mat-label>
                <input matInput [matDatepicker]="pickerF" formControlName="fecha_fin" readonly>
                <mat-datepicker-toggle matSuffix [for]="pickerF"></mat-datepicker-toggle>
                <mat-datepicker #pickerF></mat-datepicker>   
                <mat-error *ngIf='docCorrespondeForm.controls["fecha_fin"].hasError("required")'>Campo
                  requerido</mat-error>                 
              </mat-form-field>
            </td>
         
          </tr>
        </tbody>
      </table>
    </form>       
 <!--    </div> -->
 <!--  -->
 <div style="font-size: 12px">
  <div class="row">
    <div class="col-md-12">
      <mat-form-field style="width:100%;" appearance="outline">
        <mat-label>Información relevante que considere para el trámite</mat-label>
        <textarea matInput placeholder="" class="text-uppercase" rows="5"  formControlName="notas_adicionales" maxlength="1000"></textarea>
        <small class="form-text text-muted">{{ importacionForm.get('notas_adicionales').value?.length }}/1000<br /></small>
        <mat-error *ngIf="importacionForm.get('notas_adicionales').hasError('pattern')">No se permiten espacios al inicio o al final</mat-error>
      </mat-form-field>
    </div>
  </div> <br> 
      <div class="form-control" style="font-size: 10px">
        <div class="col-md-12">
          YO <strong>{{this._usuarioService.usuario.nombre}}</strong> CON CI.
          <strong>{{this._usuarioService.usuario.ci}}</strong> {{declaracion}}
        </div>
      </div>
      <div mat-dialog-actions class="mt-3 mb-1">
        <button mat-raised-button class="nuevo-button" [disabled]="!formsAreValid()" (click)="guardarImportacion()">
          <mat-icon>save</mat-icon>Guardar
        </button>&nbsp;&nbsp;
        <button mat-raised-button color="warn" style="height: 45px;" [mat-dialog-close]="false">
          <mat-icon>cancel</mat-icon> Cancelar
        </button>
      </div>

    </div>
  </mat-card-content>
</form>
